import { useEffect } from "react";
import { useLocation, useNavigationType } from 'react-router-dom';
import awsRum from "../monitoring/aws-rum";
import AuthService from "../service/authService";

// Custom hook to handle automatic page view recording
function usePageViews() {
    const location = useLocation();
    const navigationType = useNavigationType();

    useEffect(() => {
        const obfuscateUrl = (url: string): string => {
            // Remplaces the uuids in the url by a generic :id
            const uuidPattern = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
            return url.replace(uuidPattern, ':id');
        }

        const recordView = () => {
            const maskedPath = obfuscateUrl(location.pathname);
            if (!awsRum) {
                // Fail silently
                return;
            }
            // Get a few non-personal info to make filtering easier
            const currentUser = AuthService.getUserInfo();
            awsRum.recordPageView({
                pageId: maskedPath,
                pageTags: ['auto-recorded'],
                pageAttributes: {
                    originalPath: location.pathname,
                    maskedPath: maskedPath,
                    navigationType: navigationType,
                    search: location.search,
                    hash: location.hash,
                    pathname: location.pathname,
                    isAdmin: currentUser?.isAdmin || currentUser?.isDesigner,
                    language: currentUser?.language,
                }
            });
        };

        // Small delay to ensure the page has properly loaded
        // Adjust or remove if needed based on your app's behavior
        const timeoutId = setTimeout(recordView, 100);
        return () => clearTimeout(timeoutId);

    }, [location, navigationType]);
}

// Wrapper component to apply automatic page view recording
const RumHistoryListener = (props: any ) => {
    usePageViews();
    return <>{props.children}</>;
}

export default RumHistoryListener;
