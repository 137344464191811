import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "../routes";
import { AxiosInterceptors } from "../routes/AxiosInterceptors";
import "../styles/global.scss";
import SegmentHistoryListener from "./SegmentHistoryListener";
import RumHistoryListener from "./RumHistoryListener";
import "./i18n";

import "@stripe/stripe-js"; //Recommended by Stripe to put the import here (see https://github.com/stripe/stripe-js)
import "material-symbols";
import "moment/min/locales";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ErrorFallback } from "../common/components/ErrorFallback/ErrorFallback";
import { AppLoadingProvider, GlobalLoadingProvider } from "../context";
import { SpecsProvider } from "../context/Specs/SpecsProvider";
import { theme } from "../styles/theme";

const App = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const langFromParams = searchParams.get("lang");

  useEffect(() => {
    if (langFromParams) {
      i18n.changeLanguage(langFromParams);
    }
    // eslint-disable-next-line
  }, [langFromParams]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalLoadingProvider>
        <AppLoadingProvider>
          <SpecsProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <RumHistoryListener>
                <SegmentHistoryListener>
                  <Box>
                    <CssBaseline>
                      <AxiosInterceptors />
                      <Routes />
                    </CssBaseline>
                  </Box>
                  <ToastContainer
                    position="bottom-center"
                    pauseOnFocusLoss={false}
                    newestOnTop
                    style={{ width: "800px" }}
                  />
                </SegmentHistoryListener>
              </RumHistoryListener>
            </ErrorBoundary>
          </SpecsProvider>
        </AppLoadingProvider>
      </GlobalLoadingProvider>
    </ThemeProvider>
  );
};

export default App;
