import { cloneDeep, debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import xImg from "../../../../assets/editor/X.svg";
import ColorCircle from "../../../../common/components/Select/ColorSelect/ColorCircle";
import DesignStateService from "../../../../service/DesignStateService";
import TechpackService from "../../../../service/TechpackService";
import { BEHAVES_LIKE } from "../../../../utils/Constant";
import { inchesToCm } from "../../../../utils/LocaleUtil";
import styles from "../techpackArtwork.module.scss";

const TechpackArtworkDescription = (props) => {
  const {
    decoration,
    decorationDefinition,
    productName,
    productionDecoration,
    calibrationColorsEnabled = false,
    techpackId,
    techpackItemId,
    techpackItemArtwork,
    setSaving,
  } = props;

  const { t } = useTranslation();

  const [backgroundColorDef, setBackgroundColorDef] = useState(null);
  const [stitchesColorDef, setStitchesColorDef] = useState(null);
  const [productionCalibrationColors, setProductionCalibrationColors] =
    useState([
      { name: "", hex: "" },
      { name: "", hex: "" },
      { name: "", hex: "" },
      { name: "", hex: "" },
    ]);

  const tagDetails = useMemo(() => {
    try {
      // To keep legacy design working
      if (decoration.embroideryPatchDetails) {
        decoration.tagDetails = decoration.embroideryPatchDetails;
      }
      const tagName = decoration?.tagDetails?.tagName;
      const selectedTagSize = decorationDefinition?.tagOptions?.find(
        (t) => t.name === tagName
      );

      const decorationMaxWidth =
        selectedTagSize.widthInInches - 2 * selectedTagSize.borderX;
      const decorationMaxHeight =
        selectedTagSize.heightInInches - 2 * selectedTagSize.borderY;

      const decorationBoxRatio = decorationMaxWidth / decorationMaxHeight;
      const assetRatio = decoration.ratio;
      let assetWidth = "";
      let assetHeight = "";
      if (decorationBoxRatio < assetRatio) {
        // Width is limiting
        assetWidth = inchesToCm(decorationMaxWidth);
        assetHeight = inchesToCm(decorationMaxWidth / assetRatio);
      } else {
        // Height is limiting
        assetWidth = inchesToCm(decorationMaxHeight * assetRatio);
        assetHeight = inchesToCm(decorationMaxHeight);
      }

      const planeAngle = decoration.location?.selectedLocation?.planeAngle;
      if ((planeAngle / 90) % 2) {
        // Flip width and height because of the plane angle
        return {
          assetWidth,
          assetHeight,
          tagWidth: inchesToCm(
            selectedTagSize.heightInInches +
              (2 * selectedTagSize?.stitchThicknessInches || 0)
          ),
          tagHeight: inchesToCm(
            selectedTagSize.widthInInches +
              (2 * selectedTagSize?.stitchThicknessInches || 0)
          ),
          planeAngle,
          moldCode: selectedTagSize?.moldCode,
        };
      }

      if (decoration.tagDetails?.backgroundFabricName) {
        if (!decoration.tagDetails?.backgroundColorName?.startsWith("#")) {
          DesignStateService.getFabricColors(
            decoration.tagDetails?.backgroundFabricName
          ).then((colors) => {
            const bgColorDef = colors?.find(
              (c) => c.name === decoration.tagDetails?.backgroundColorName
            );
            // Set background color
            setBackgroundColorDef(bgColorDef);
          });
        } else {
          // This is a custom color
          setBackgroundColorDef({
            hex: decoration.tagDetails?.backgroundColorName,
            chartName: "Custom",
            name: "Custom",
            label: decoration.tagDetails?.backgroundColorName,
          });
        }
      }

      if (
        decorationDefinition.behavesLike === BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE
      ) {
        if (!decoration.tagDetails?.stitchColorName?.startsWith("#")) {
          if (decoration.tagDetails?.stitchFabricName) {
            DesignStateService.getFabricColors(
              decoration.tagDetails?.stitchFabricName
            ).then((colors) => {
              const stitchDef = colors?.find(
                (c) => c.name === decoration.tagDetails?.stitchColorName
              );
              // Set stitches color
              setStitchesColorDef(stitchDef);
            });
          } else {
            // Older designs might not have stitchFabricName set. In that case, get the fabric via
            // the asset manager. The stitches are the same color as the embroidery.
            const stitchDef = decoration.fabricColorCodes?.find(
              (c) => c.name === decoration.tagDetails?.stitchColorName
            );
            setStitchesColorDef(stitchDef);
          }
        } else {
          // This is a custom color
          setStitchesColorDef({
            hex: decoration.tagDetails?.stitchColorName,
            chartName: "Custom",
            name: "Custom",
            label: decoration.tagDetails?.stitchColorName,
          });
        }
      }

      return {
        assetWidth,
        assetHeight,
        tagWidth: inchesToCm(
          selectedTagSize.widthInInches +
            (2 * selectedTagSize?.stitchThicknessInches || 0)
        ),
        tagHeight: inchesToCm(
          selectedTagSize.heightInInches +
            (2 * selectedTagSize?.stitchThicknessInches || 0)
        ),
        planeAngle,
        moldCode: selectedTagSize?.moldCode,
      };
    } catch (e) {
      return undefined; // If not a tag, the function will fail and it's ok.
    }
  }, [decorationDefinition, decoration]);

  const repeatingPatternLogoSize = useMemo(() => {
    try {
      const maxImageWidthInches =
        decoration?.repeatingDetails?.maxImageWidthInches;
      const maxImageHeightInches =
        decoration?.repeatingDetails?.maxImageHeightInches;
      const ratio = parseFloat(decoration.ratio);
      if (ratio > 1) {
        return {
          width: maxImageWidthInches,
          height: maxImageHeightInches / ratio,
        };
      }
      return {
        width: maxImageWidthInches * ratio,
        height: maxImageHeightInches,
      };
    } catch (e) {
      return undefined; // If not a tag, the function will fail and it's ok.
    }
  }, [decoration]);

  //Initial load (for detailed prints)
  useEffect(() => {
    const defaultCalibrationColors = [
      { name: "", hex: "" },
      { name: "", hex: "" },
      { name: "", hex: "" },
      { name: "", hex: "" },
    ];
    if (productionDecoration && productionDecoration?.calibrationColors) {
      productionDecoration.calibrationColors.forEach(
        (calibrationColor, index) => {
          //For now we limit only to 4 colors
          if (index < 4) {
            if (typeof calibrationColor === "string") {
              // Change the format for an object
              defaultCalibrationColors[index].name = calibrationColor;
            } else {
              defaultCalibrationColors[index] = calibrationColor;
            }
          }
        }
      );
    }
    setProductionCalibrationColors(defaultCalibrationColors);
  }, [productionDecoration, productionDecoration?.calibrationColors]);

  const saveCalibrationColors = debounce(async (calibrationColors) => {
    const productionArtwork = {
      techpackId,
      techpackItemId,
      techpackItemArtworkId: techpackItemArtwork.id,
      calibrationColors,
    };

    return await TechpackService.updateProductionArtworkCalibrationColors(
      productionArtwork
    );
  });

  /**
   *
   */
  const onCalibrationColorChange = useCallback(
    async (index, key, value) => {
      setSaving(true);
      const newCalibrationColors = cloneDeep(productionCalibrationColors);
      newCalibrationColors[index][key] = value;
      setProductionCalibrationColors(newCalibrationColors);
      await saveCalibrationColors(newCalibrationColors);
      setSaving(false);
    },
    [
      setSaving,
      productionCalibrationColors,
      setProductionCalibrationColors,
      saveCalibrationColors,
    ]
  );

  const clearColorSelection = useCallback(
    async (index) => {
      setSaving(true);
      const newCalibrationColors = cloneDeep(productionCalibrationColors);
      newCalibrationColors[index].name = "";
      newCalibrationColors[index].hex = "";
      setProductionCalibrationColors(newCalibrationColors);
      await saveCalibrationColors(newCalibrationColors);
      setSaving(false);
    },
    [
      setSaving,
      productionCalibrationColors,
      setProductionCalibrationColors,
      saveCalibrationColors,
    ]
  );

  return (
    <div className={styles.decorationDescription}>
      <span className={styles.decorationType}>
        {
          decorationDefinition.displayName.en // TODO: add translation
        }
      </span>
      <span className={styles.decorationPosition}>
        {/* {t("specs.position")}:{" "} */}
        {t(
          "positions." + decoration.location.selectedLocation.positionName
        )}{" "}
        {` ${t("techpack.on")} `}
        {t(
          `specs.${productName}.${decoration.location.selectedLocation.partName}`
        )}
      </span>
      <span>{`(${decoration.location.selectedLocation.positionId}) `}</span>
      {
        // The way we display the size and spacing info is different depending
        // on if the decoration is a tag/patch, a repeating pattern or anything
        // else. The code below displays the proper info based on the decoration
        // type.
      }
      {decorationDefinition.behavesLike === BEHAVES_LIKE.WOVEN_TAG_TYPE ||
      decorationDefinition.behavesLike === BEHAVES_LIKE.LEATHER_TAG_TYPE ||
      decorationDefinition.behavesLike ===
        BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE ? (
        <span className={styles.decorationSize}>
          {/* Dimensions: W:{" "} */}
          {decorationDefinition.behavesLike ===
          BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE ? (
            <b>Patch size</b>
          ) : (
            <b>Tag size</b>
          )}
          <br />
          W: {tagDetails?.tagWidth}, H: {tagDetails?.tagHeight}
          {/* , Scale: , */}
          <br />
          <b>Asset size</b>
          <br />
          W: {tagDetails?.assetWidth}, H: {tagDetails?.assetHeight}
        </span>
      ) : // If there is a repeating pattern, display the details
      decorationDefinition.repeatingPatternOptions ? (
        <React.Fragment>
          <span className={styles.decorationSize}>
            {t(
              `specs.repeating_patterns.${decoration.repeatingDetails.patternName}`
            )}
          </span>

          <span className={styles.decorationSize}>
            {t("techpack.logo")} {inchesToCm(repeatingPatternLogoSize?.width)}
            {" x "}
            {inchesToCm(repeatingPatternLogoSize?.height)}
          </span>
          <span className={styles.decorationSize}>
            Spacing Vertical:{" "}
            {inchesToCm(decoration.repeatingDetails.verticalSpacingInches)}
          </span>
          <span className={styles.decorationSize}>
            Spacing Horizontal:{" "}
            {inchesToCm(decoration.repeatingDetails.horizontalSpacingInches)}
          </span>
          <span className={styles.decorationSize}>
            Offset Vertical:{" "}
            {inchesToCm(decoration.repeatingDetails.verticalOffsetInches)}
          </span>
          <span className={styles.decorationSize}>
            Offset Horizontal:{" "}
            {inchesToCm(decoration.repeatingDetails.horizontalOffsetInches)}
          </span>
          {/* Some design might contain the old hood lining size. Might be confusing. */}
          {/*<span className={styles.decorationSize}>*/}
          {/*  Total Width: {inchesToCm(decoration.location.selectedLocation.maxWidthInches)},*/}
          {/*  Total Height: {inchesToCm(decoration.location.selectedLocation.maxHeightInches)}*/}
          {/*  <br />*/}
          {/*</span>*/}
        </React.Fragment>
      ) : (
        decoration?.assetSize?.selectedSize && (
          <span className={styles.decorationSize}>
            W: {inchesToCm(decoration.assetSize.selectedSize.widthInInches)}, H:{" "}
            {inchesToCm(decoration.assetSize.selectedSize.heightInInches)}
          </span>
        )
      )}

      {
        // Depending on the decoration type, display the proper information
        // regarding the colors/textures.
        // Leather tag: Display the texture & color
      }
      {decorationDefinition.behavesLike === BEHAVES_LIKE.LEATHER_TAG_TYPE && (
        <span className={styles.decorationTextureName}>
          Texture: {t(`textures.${decoration.tagDetails.textureName}`)}
        </span>
      )}
      {
        // Any decoration that have the field fabricColorCodes set, we display
        // the colors used by the asset.
      }
      {decoration.fabricColorCodes && (
        <div className={styles.decorationSelectedColors}>
          <span className={styles.decorationColorTitle}>
            {t("techpack.logo_colors")}
          </span>

          {decoration.asset.selectedColors
            .filter(
              (selectedColor) => selectedColor.transparent === false // Remove transparent color from the list
            )
            .filter((selectedColor, pos) => {
              // Remove duplicated colors
              const selectedColorNames = decoration.asset.selectedColors.map(
                (c) => c?.chartColor?.name
              );
              const currentColorName = selectedColor?.chartColor?.name;
              return selectedColorNames.indexOf(currentColorName) === pos;
            })
            .map((selectedColor, selectedColorIndex) => {
              const isCustom = selectedColor.chartColor.name.startsWith("#");
              const fabricColor = decoration.fabricColorCodes.find(
                (colorCode) => colorCode.name === selectedColor.chartColor.name
              );
              const colorHex = isCustom
                ? selectedColor.chartColor.name
                : fabricColor.hex;
              const chartName = isCustom
                ? "Custom"
                : fabricColor?.chartName || "UW chart";
              const label = isCustom
                ? colorHex
                : t("colors." + fabricColor.label);
              const techpackName = isCustom
                ? "TODO"
                : selectedColor.chartColor?.techpackColorName ||
                  selectedColor.chartColor.name;
              return (
                <div
                  className={styles.decorationSelectedColor}
                  key={selectedColorIndex}
                >
                  <ColorCircle color={colorHex} label={label} />
                  <div className={styles.description}>
                    <span>
                      {chartName}
                      {" - "}
                      {label}
                      {/*Use techpackColorName first, if not exists then use label*/}
                      {` ( ${techpackName} )`}
                    </span>
                    <span className={styles.hexValue}>{colorHex}</span>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {
        // If a background exists for the tag, display it
      }
      {(decorationDefinition.behavesLike === BEHAVES_LIKE.WOVEN_TAG_TYPE ||
        decorationDefinition.behavesLike === BEHAVES_LIKE.LEATHER_TAG_TYPE ||
        decorationDefinition.behavesLike ===
          BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE) &&
        backgroundColorDef && (
          <div className={styles.decorationSelectedColor}>
            <ColorCircle
              color={backgroundColorDef.hex}
              label={backgroundColorDef.label}
            />
            <div className={styles.description}>
              <span>
                {backgroundColorDef?.chartName || "UW chart"}
                {" - "}
                {backgroundColorDef.label?.startsWith("#")
                  ? backgroundColorDef.label
                  : t("colors." + backgroundColorDef.label)}
                {` ${backgroundColorDef.name}`}
              </span>
              <span className={styles.hexValue}>
                {`${backgroundColorDef.hex} - Background`}
              </span>
            </div>
          </div>
        )}
      {
        // If stitches exists for the tag, display it
      }
      {decorationDefinition.behavesLike ===
        BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE &&
        stitchesColorDef && (
          <div className={styles.decorationSelectedColor} key="stichColor">
            <ColorCircle
              color={stitchesColorDef.hex}
              label={stitchesColorDef.label}
            />
            <div className={styles.description}>
              <span>
                {stitchesColorDef.chartName || "UW chart"}
                {" - "}
                {stitchesColorDef.label?.startsWith("#")
                  ? stitchesColorDef.label
                  : t("colors." + stitchesColorDef.label)}
                {` ${stitchesColorDef.name}`}
              </span>
              <span className={styles.hexValue}>
                {`${stitchesColorDef.hex} - Stitches`}
              </span>
            </div>
          </div>
        )}

      {decorationDefinition.behavesLike ===
        BEHAVES_LIKE.DETAILED_PRINT_TYPE && (
        <div className={styles.decorationCalibrations}>
          <span className={styles.decorationCalibrationsTitle}>
            {t("techpack.calibration")}
          </span>

          {/**For now we hardcode to 4 calibration colors */}
          <div className={styles.decorationCalibration}>
            {productionCalibrationColors?.map((calibrationColor, index) => {
              return (
                // If we set the calibration color before a production artwork was set,
                // the backend returns an error 400 and the colors are not saved. For
                // now, disable the calibration color as long as the production artwork
                // is not set.
                <div
                  className={styles.decorationCalibrationLine}
                  style={{
                    opacity: calibrationColorsEnabled ? 1 : 0.25,
                    pointerEvents: calibrationColorsEnabled
                      ? "initial"
                      : "none",
                  }}
                  key={index}
                >
                  <input
                    type="text"
                    value={calibrationColor?.name || ""}
                    onChange={(e) => {
                      onCalibrationColorChange(index, "name", e.target.value);
                    }}
                  ></input>

                  <input
                    type="color"
                    value={calibrationColor?.hex || "#FFFFFF"}
                    onChange={(e) => {
                      onCalibrationColorChange(index, "hex", e.target.value);
                    }}
                  ></input>
                  <span className={styles.xButton}>
                    <img
                      src={xImg}
                      width={16}
                      alt="logo"
                      onClick={() => clearColorSelection(index)}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TechpackArtworkDescription;
