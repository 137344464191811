import { Team } from "../../types";
import { Action } from "../../types/redux";

export enum MY_TEAMS_ACTION_ENUM {
  UPDATE_MY_TEAMS = "update_my_teams",
  UPDATE_MY_TEAMS_LOADING = "update_my_teams_loading",
  RESET_STATE = "reset_state",
}

const initialState = {
  teams: [] as Team[],
  loading: true,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<MY_TEAMS_ACTION_ENUM, Team[]>
) => {
  switch (action.type) {
    case MY_TEAMS_ACTION_ENUM.UPDATE_MY_TEAMS:
      return { teams: action.payload, loading: false, loaded: true };
    case MY_TEAMS_ACTION_ENUM.UPDATE_MY_TEAMS_LOADING:
      return {
        teams: [...state.teams],
        loading: true,
        loaded: !!state.teams.length,
      };
    case MY_TEAMS_ACTION_ENUM.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
