import Button from "@mui/material/Button";
import { cloneDeep, debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TechpackService from "../../service/TechpackService";
import { ROUTES } from "../../utils/Constant";
import { routeFormatter } from "../../utils/Formatter";
import TechpackArtwork from "./TechpackArtwork";
import TechpackCustomColor from "./TechpackCustomColor";
import styles from "./techpackItem.module.scss";

const TechpackItem = (props) => {
  const {
    techpackId,
    techpackItem,
    techpackItemIndex,
    techpackitemsCount,
    setSaving,
    orderType,
    orderId,
  } = props;

  const [sizesQuantities, setSizesQuantities] = useState(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const decorationDefinitions = techpackItem.decorationDefinitions;

  const customColors = techpackItem.techpackitemcustomcolors;

  const purchasedItem = techpackItem.purchased_item;

  const images = purchasedItem.designState.images;

  const designName = purchasedItem.designName;
  const projectName = purchasedItem.projectName;

  const designWithColors = purchasedItem.designState.designWithColors;

  //For initial setting of size quantities
  useEffect(() => {
    setSizesQuantities(techpackItem.techpackitemquantities);
  }, [techpackItem.techpackitemquantities]);

  const techpackItemArtworks = techpackItem.techpackitemartworks;

  /**
   *
   */
  const onVersionChange = useCallback(
    async (event) => {
      const saveVersion = debounce(
        async (e) => {
          const newVersion = e.target.value || ""; //Null should be ""

          const data = {
            techpackId: techpackId,
            techpackItemId: techpackItem.id,
            version: newVersion,
          };
          await TechpackService.setVersion(data);
          setSaving(false);
        },
        100,
        { leading: true }
      );

      setSaving(true);
      await saveVersion(event);
      setSaving(false);
    },
    [techpackId, techpackItem.id, setSaving]
  );

  /**
   *
   */
  const onQtyChange = useCallback(
    async (sizeField, sizeName, sizeQty) => {
      const saveSizeQty = debounce(
        async (field, name, qty) => {
          const data = {
            techpackId: techpackId,
            techpackItemId: techpackItem.id,
            sizes: [
              {
                field: field,
                sizeName: name,
                qty: qty,
              },
            ],
          };

          await TechpackService.setSize(data);
        },
        100,
        { leading: true }
      );

      setSaving(true);
      await saveSizeQty(sizeField, sizeName, sizeQty);

      //Updating local state now that the API call is done (faster than reload the full techpack...)
      setSizesQuantities((oldSizes) => {
        const newSizes = cloneDeep(oldSizes);
        const sizeToUpdate = newSizes.find(
          (size) => size.size_name === sizeName
        );

        switch (sizeField) {
          case "ORDER": {
            sizeToUpdate.order_quantity = sizeQty;
            break;
          }
          case "OVER": {
            sizeToUpdate.over_quantity = sizeQty;
            break;
          }
          case "MARKETING": {
            sizeToUpdate.marketing_quantity = sizeQty;
            break;
          }
          case "QC": {
            sizeToUpdate.qc_quantity = sizeQty;
            break;
          }
          default: {
            //Nothing to do
          }
        }

        return newSizes;
      });

      setSaving(false);
    },
    [techpackId, techpackItem.id, setSaving]
  );

  const onClickEdit = useCallback(() => {
    //navigate(routeFormatter(ROUTES.TEAM_PROJECTS, { params: { id } }));
    navigate(
      routeFormatter(ROUTES.STUDIO_PURCHASED_DESIGN, {
        params: {
          designId: purchasedItem.designState.designId,
          orderType: orderType,
          orderId: orderId,
          historyFrom: "techpack",
        },
      })
    );
  }, [navigate, purchasedItem.designState.designId, orderType, orderId]);

  return (
    <div className={styles.container}>
      <div className={styles.titleAndActions}>
        <div className={styles.nameAndDescription}>
          <h5>
            {`${projectName} - ${designName} (${techpackItemIndex}/${techpackitemsCount})`}{" "}
          </h5>
        </div>
        <div className={styles.actionContainer}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              marginRight: "10px",
              backgroundColor: "#2484BA",
              textTransform: "capitalize",
            }}
            onClick={onClickEdit}
          >
            {t("techpack.edit_design")}
          </Button>
        </div>
      </div>

      <div className={styles.itemParameters}>
        <div className={styles.editableField}>
          <div className={styles.fieldLabel}>
            <span>{t("techpack.version")}</span>{" "}
            <span className={styles.disabled}>{t("techpack.optional")}</span>
          </div>
          <input
            type="text"
            className={styles.versionNumber}
            defaultValue={techpackItem.version}
            onChange={onVersionChange}
          />
        </div>
      </div>
      <div className={styles.generalInfo}>
        <div className={styles.imgContainer}>
          {/** Here use glass component*/}
          {images.map((image) => (
            <img
              src={image.url}
              alt={image.side}
              className={styles.img}
              key={image.side}
            />
          ))}
        </div>

        <div className={styles.designStateContainer}>
          <div className={styles.designStateDetails}>
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              style={{
                tableLayout: "fixed",
                paddingLeft: "20px",
                margin: "auto",
              }}
            >
              <colgroup>
                <col span="1" style={{ width: "75%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    {designWithColors.parts.map((part) => (
                      <React.Fragment key={part.name}>
                        <span>
                          <b>
                            {t(
                              `specs.${designWithColors.product.name}.${part.name}`
                            )}
                          </b>
                        </span>
                        <ul>
                          {part.elements.map(
                            (element) =>
                              element.active && (
                                <React.Fragment key={element.name}>
                                  <li>
                                    {/* Element{" "} */}
                                    {t(
                                      `specs.${designWithColors.product.name}.${element.name}`
                                    )}
                                    {": "}
                                    {/* Style{" "} */}
                                    {t(
                                      `specs.${designWithColors.product.name}.${element.style.name}`
                                    )}
                                  </li>
                                  <li style={{ listStyleType: "none" }}>
                                    <ul>
                                      {element.style.layers.map(
                                        (layer) =>
                                          layer.active && (
                                            <React.Fragment key={layer.name}>
                                              <li>
                                                <span>
                                                  {t(
                                                    `specs.${designWithColors.product.name}.${layer.name}`
                                                  )}
                                                  {": "}
                                                  {/*If the color is an HEX, the colorName will be the HEX but it will not have a colorLabel */}
                                                  {layer.colorLabel &&
                                                    t(
                                                      `colors.${layer.colorLabel}`
                                                    )}{" "}
                                                  (
                                                  {layer?.techpackColorName ||
                                                    layer.colorName}
                                                  ) - {layer.hex}
                                                </span>
                                              </li>
                                            </React.Fragment>
                                          )
                                      )}
                                    </ul>
                                  </li>
                                </React.Fragment>
                              )
                          )}
                        </ul>
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={styles.sizeSelection}>
        <div className={styles.tableRow}>
          <div className={styles.sizeField} key={"title"}>
            <div className={styles.sizeName}>&nbsp;</div>
            <div className={styles.sizeQtyTitle}>
              <span>{t("techpack.order_quantity")}</span>
            </div>
            <div className={styles.sizeQtyTitle}>
              <span>{t("techpack.over_quantity")}</span>
            </div>
            <div className={styles.sizeQtyTitle}>
              <span>{t("techpack.qc_quantity")}</span>
            </div>
            <div className={styles.sizeQtyTitle}>
              <span>{t("techpack.marketing_quantity")}</span>
            </div>
            <div className={styles.sizeQtyTitle}>
              <span>{t("techpack.total")}</span>
            </div>
          </div>

          {sizesQuantities
            ?.sort((a, b) => a.display_order - b.display_order)
            .map((size) => (
              <div className={styles.sizeField} key={size.size_name}>
                <div className={styles.sizeName} key="sizeName">
                  <span>{t(`cart.sizesFromCartItem.${size.size_name}`)}</span>
                </div>
                <div className={styles.sizeQtyTitle} key="order_quantity">
                  <span>{size.order_quantity}</span>
                </div>
                <div className={styles.sizeQty} key="over_quantity">
                  <input
                    type="number"
                    min="0"
                    style={styles.editableSize}
                    defaultValue={size.over_quantity}
                    onChange={(event) =>
                      onQtyChange("OVER", size.size_name, event.target.value)
                    }
                  />
                </div>
                <div className={styles.sizeQty} key="qc_quantity">
                  <input
                    type="number"
                    min="0"
                    style={styles.editableSize}
                    defaultValue={size.qc_quantity}
                    onChange={(event) =>
                      onQtyChange("QC", size.size_name, event.target.value)
                    }
                  />
                </div>
                <div className={styles.sizeQty} key="marketing_quantity">
                  <input
                    type="number"
                    min="0"
                    style={styles.editableSize}
                    defaultValue={size.marketing_quantity}
                    onChange={(event) =>
                      onQtyChange(
                        "MARKETING",
                        size.size_name,
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className={styles.sizeName} key="total">
                  <span>
                    {Number(size.marketing_quantity) +
                      Number(size.qc_quantity) +
                      Number(size.over_quantity) +
                      Number(size.order_quantity)}
                  </span>
                </div>
              </div>
            ))}
          <div className={styles.sizeField} key="orderTotalCol">
            <div className={styles.sizeName} key="orderTotalTitle">
              <span style={{ textTransform: "uppercase" }}>Total</span>
            </div>
            <div className={styles.sizeQty} key="order_quantity">
              {sizesQuantities?.reduce(
                (accumulator, curr) =>
                  accumulator + Number(curr?.order_quantity),
                0
              )}
            </div>
            <div className={styles.sizeQty} key="over_quantity">
              {sizesQuantities?.reduce(
                (accumulator, curr) =>
                  accumulator + Number(curr?.over_quantity),
                0
              )}
            </div>
            <div className={styles.sizeQty} key="qc_quantity">
              {sizesQuantities?.reduce(
                (accumulator, curr) => accumulator + Number(curr?.qc_quantity),
                0
              )}
            </div>
            <div className={styles.sizeQty} key="marketing_quantity">
              {sizesQuantities?.reduce(
                (accumulator, curr) =>
                  accumulator + Number(curr?.marketing_quantity),
                0
              )}
            </div>
            <div className={styles.sizeName} key="orderTotal">
              <span>
                {String(
                  sizesQuantities?.reduce(
                    (accumulator, curr) =>
                      accumulator + Number(curr?.order_quantity),
                    0
                  ) +
                    sizesQuantities?.reduce(
                      (accumulator, curr) =>
                        accumulator + Number(curr?.over_quantity),
                      0
                    ) +
                    sizesQuantities?.reduce(
                      (accumulator, curr) =>
                        accumulator + Number(curr?.qc_quantity),
                      0
                    ) +
                    sizesQuantities?.reduce(
                      (accumulator, curr) =>
                        accumulator + Number(curr?.marketing_quantity),
                      0
                    )
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      {customColors && customColors.length > 0 && (
        <div className={styles.customColorContainer}>
          <TechpackCustomColor
            customColors={customColors}
            setSaving={setSaving}
          />
        </div>
      )}

      <div className={styles.decorationsContainer}>
        {/* <span className={styles.artworkTitle}>{t("specs.decorations")}</span> */}
        {techpackItemArtworks
          .filter((techpackItemArtwork) => {
            const decoration = techpackItemArtwork.decoration;
            return (
              decoration?.assetId && decoration.active && !decoration.inError
            );
          })
          .map(
            (
              techpackItemArtwork,
              techpackItemArtworkIndex,
              filteredTechpackItemArtworks
            ) => {
              const decoration = techpackItemArtwork.decoration;
              let decorationDefinition = decorationDefinitions.find(
                (d) => d.id === decoration.type
              );
              if (!decorationDefinition) {
                decorationDefinition = decorationDefinitions.find((d) =>
                  d.legacyAssetTypes.includes(decoration.type)
                );
              }
              //const productionDecoration = techpackItemArtwork.production_decoration;

              return (
                <TechpackArtwork
                  techpackId={techpackId}
                  techpackItemId={techpackItem.id}
                  techpackItemArtwork={techpackItemArtwork}
                  techpackItemArtworkIndex={techpackItemArtworkIndex}
                  techpackItemArtworkCount={filteredTechpackItemArtworks.length}
                  decoration={decoration}
                  productName={designWithColors.product.name}
                  decorationDefinition={decorationDefinition}
                  setSaving={setSaving}
                />
              );
            }
          )}
      </div>
    </div>
  );
};

export default TechpackItem;
