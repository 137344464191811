import { groupBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import SideMenuDecorations from "./SideMenuDecorations";

const Decorations = (props) => {
  const {
    decorations,
    onSelectionChange,
    onDesignChange,
    selection,
    availableDecorationsDefinition,
    canUpdateArtworkPosition,
    canAddArtwork,
    canDeleteArtwork,
  } = props;

  const { t } = useTranslation();

  const availableGroups = useMemo(() => {
    const decorationGroupByType = groupBy(
      availableDecorationsDefinition,
      (d) => d.group
    );
    return Object.keys(decorationGroupByType)
      .map((key) => {
        return {
          group: key,
          weight: decorationGroupByType[key].reduce(
            (a, c) => a + c?.display?.listOrder,
            0
          ),
        };
      })
      .sort((a, b) => a.weight - b.weight)
      .map((gw) => gw.group);
  }, [availableDecorationsDefinition]);

  const addOptions = availableGroups?.map((group) => {
    let label = `specs.decoration_groups.${group}`;
    if (group === "print") {
      label = availableDecorationsDefinition.some(
        (d) => d.name === "embroidery" && !d.deprecated
      )
        ? availableDecorationsDefinition.some(
            (d) =>
              d.name !== "embroidery" && d.group === "print" && !d.deprecated
          )
          ? `specs.decoration_groups.printOrEmbroidery`
          : `specs.decoration_groups.embroidery`
        : `specs.decoration_groups.print`;
    }
    return {
      labelKey: label,
      onClick: async () => {
        const decorationId = uuidv4();

        await onDesignChange(DESIGN_CHANGE_ENUM.ADD_DECORATION, {
          group: group,
          decorationId: decorationId,
          name: t(label),
        });

        onSelectionChange("Decoration", decorationId);
      },
      disabled: !canAddArtwork,
    };
  });

  const options = decorations?.map((decoration) => ({
    label: decoration.name,
    deprecated: decoration.deprecated,
    display: {
      menuIconUrl: decoration.icon || "",
      displayable: true,
      displayMethod: "icon",
    },
    onClick: () => onSelectionChange("Decoration", decoration.id),
    onHide: (e) => {
      e.stopPropagation();
      onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_ACTIVE, {
        decorationId: decoration.id,
        active: false,
      });
    },
    onShow: (e) => {
      e.stopPropagation();
      onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_ACTIVE, {
        decorationId: decoration.id,
        active: true,
      });
    },
    onDelete: (e) => {
      e.stopPropagation();
      onSelectionChange("", "", "");
      onDesignChange(DESIGN_CHANGE_ENUM.ON_REMOVE, {
        decorationId: decoration.id,
      });
    },
    isSelected:
      selection.selectedPart === "Decoration" &&
      selection.selectedElementName === decoration.id,
    canDelete: canDeleteArtwork,
    canHide: canUpdateArtworkPosition,
    active: decoration.active && !decoration.inError,
    id: decoration.id,
  }));

  return <SideMenuDecorations addOptions={addOptions} decorations={options} />;
};

export default Decorations;
