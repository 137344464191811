import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";

import specsEN from "../locales/en/specs.json";
import specsFR from "../locales/fr/specs.json";

import productEN from "../locales/en/product.json";
import productFR from "../locales/fr/product.json";

import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: { translation: { ...translationEN, ...specsEN, ...productEN } },
      fr: { translation: { ...translationFR, ...specsFR, ...productFR } },
    },
    supportedLngs: ["fr", "en"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
