import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Trans, useTranslation } from "react-i18next";

import DialogTitleWithCloseButton from "../DialogTitleWithCloseButton/DialogTitleWithCloseButton";

import { Divider, Typography } from "@mui/material";
import { uniq } from "lodash";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  Box,
  FlexColBox,
  Icon,
  Input,
  LanguageSwitcher,
  Link,
} from "../../common/components";
import ChipCredit from "../../common/components/Chip/GradientChip";
import { palette, spacing, Styles } from "../../styles/theme";
import {
  DesignPricing,
  DesignPricingCustomizationWithCredits,
  Size,
  SpecsProductPricing,
} from "../../types";
import { useCurrency } from "../../utils/Currencies";
import { useThemeBreakpoints } from "../../utils/themeUtils";

const styles: Styles = {
  bottomSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  designId: string;
  productName: string;
  designPricing: DesignPricing;
  pricingDef: SpecsProductPricing;
  sizesOption: Size[];
}

export const CalculatePriceModal = ({
  open,
  onClose,
  productName,
  designPricing,
  pricingDef,
}: Props) => {
  const { isMobile } = useThemeBreakpoints();
  const [qty, setQty] = useState(pricingDef.minQty);

  const { FullCurrencyFormatter, getCurrency } = useCurrency();

  const { t } = useTranslation();

  const hasMinimum = useMemo(
    () => qty >= pricingDef.minQty,
    [pricingDef.minQty, qty]
  );
  const hasReachedMaximum = useMemo(() => {
    const { pricingRange } = pricingDef.pricingCurrency[getCurrency()];
    return qty >= pricingRange[pricingRange.length - 1].qty;
  }, [getCurrency, pricingDef.pricingCurrency, qty]);

  const onQtyChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setQty(Number(e.target.value));
    },
    [setQty]
  );

  const rawUnitPrice = useMemo(() => {
    if (!hasMinimum) {
      return { price: 0, formatted: t("pricing.notMetMinimum") };
    }
    if (hasReachedMaximum) {
      return { price: 0, formatted: t("pricing.tailoredPrice") };
    }

    let price = 0;

    pricingDef.pricingCurrency[getCurrency()]?.pricingRange.some((pricing) => {
      if (qty < pricing.qty) {
        price = pricing.price;
        return true;
      }
    });

    return { price, formatted: FullCurrencyFormatter?.format(price) };
  }, [
    FullCurrencyFormatter,
    getCurrency,
    hasMinimum,
    hasReachedMaximum,
    pricingDef.pricingCurrency,
    qty,
    t,
  ]);

  const creditUnitPrice = useMemo(() => {
    const extraCredits = Math.max(
      designPricing.creditsUsedInDesign - pricingDef.freeCredits,
      0
    );

    if (!hasMinimum || hasReachedMaximum) {
      return { price: 0, formatted: "" };
    }

    const price =
      pricingDef.pricingCurrency[getCurrency()]?.priceOfExtraCredit *
      extraCredits;

    return {
      price,
      formatted: FullCurrencyFormatter?.format(price),
      extraCredits,
    };
  }, [
    designPricing.creditsUsedInDesign,
    pricingDef.freeCredits,
    pricingDef.pricingCurrency,
    hasMinimum,
    hasReachedMaximum,
    getCurrency,
    FullCurrencyFormatter,
  ]);

  const unitPrice = useMemo(() => {
    const price = rawUnitPrice.price + creditUnitPrice.price;
    return { price, formatted: FullCurrencyFormatter?.format(price) };
  }, [rawUnitPrice.price, creditUnitPrice.price, FullCurrencyFormatter]);

  const totalPrice = useMemo(() => {
    const price = unitPrice.price * qty;
    return { price, formatted: FullCurrencyFormatter?.format(price) };
  }, [unitPrice.price, qty, FullCurrencyFormatter]);

  const getCreditDetailsDisplayText = useCallback(
    (customization: DesignPricingCustomizationWithCredits) => {
      switch (customization.type) {
        case "ARTWORK":
          return `${t(`specs.decoration_types.${customization.displayLabels[0]}.title`)} - ${t(`positions.${customization.displayLabels[2]}`)}`;
        case "Optional layer":
          return t(`specs.${productName}.${customization.displayLabels[1]}`);
        case "Style":
          return t(`specs.${productName}.${customization.displayLabels[2]}`);
        default:
          return "";
      }
    },
    [productName, t]
  );

  const formattedCredits = useMemo(() => {
    const credits = designPricing.customizationsListWithCredits
      .filter(
        (c) => c.explanation !== "firstColor" && c.explanation !== "extraColor"
      )
      .map((c) => ({ ...c, name: getCreditDetailsDisplayText(c) }));

    const rawColors =
      designPricing.customizationsListWithCredits.filter(
        (c) => c.explanation === "firstColor" || c.explanation === "extraColor"
      ) ?? [];

    const fabricTypes = uniq(rawColors.map((c) => c.fabric));

    const colors = fabricTypes.map((fabric) => {
      const fabricColors = rawColors.filter((c) => c.fabric === fabric);

      return fabricColors.reduce(
        (acc, curr) => {
          return {
            ...acc,
            credits: acc.credits + curr.credits,
            name: t("pricing.creditType.colors", {
              count: fabricColors.length,
              fabricType: fabric,
            }),
          };
        },
        {
          ...fabricColors[0],
          name: t("pricing.creditType.colors", {
            count: 1,
            fabricType: fabric,
          }),
        }
      );
    });

    return [...colors, ...credits].filter((c) => !!c);
  }, [
    designPricing.customizationsListWithCredits,
    getCreditDetailsDisplayText,
    t,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={onClose}
      >
        {t("pricing.calculate_price")}
      </DialogTitleWithCloseButton>

      <DialogContent>
        <FlexColBox
          gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
        >
          <FlexColBox>
            <Typography variant="h2" mb={"8px"}>
              {t("pricing.quantity")}
            </Typography>
            <Input
              type="number"
              onChange={onQtyChange}
              value={qty}
              inputProps={{ min: 0 }}
              containerSx={{ maxWidth: "100px" }}
            />
          </FlexColBox>

          {hasReachedMaximum && (
            <FlexColBox>
              <FlexColBox
                sx={{
                  borderRadius: "4px",
                  border: `1px solid transparent`,
                  opacity: 0.8,
                  background: `linear-gradient(90deg, rgba(84, 172, 222, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%), ${palette.lightBorderGradient}`,
                }}
                padding={{
                  xs: spacing.mediumMobile,
                  lg: spacing.mediumDesktop,
                }}
                gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
              >
                <Typography
                  variant="textRegularBold"
                  color={palette.blue.darkBlue}
                >
                  {t("pricing.requestQuote")}
                </Typography>
                <Typography variant="textSm" color={palette.grey.nearBlack}>
                  <Trans i18nKey={"pricing.requestQuoteInfo"}>
                    Add the product to your cart or email
                    <Link
                      to={"mailto:info@jameo.com"}
                      style={{ fontSize: "12px" }}
                    >
                      info@jameo.com
                    </Link>
                    to request a quote.
                  </Trans>
                </Typography>
              </FlexColBox>
            </FlexColBox>
          )}

          <FlexColBox
            gap={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
          >
            <Typography variant="h2">{t("general.product")}</Typography>
            <FlexColBox
              width={"100%"}
              gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="textRegularBold">
                  {t(`products.${productName}.name`)}
                </Typography>
                <Typography variant="textRegularBold">
                  {rawUnitPrice.formatted}
                </Typography>
              </Box>
              <Typography variant="textSm" color={palette.grey.primary}>
                {t("pricing.freeCredits", { count: pricingDef.freeCredits })}
              </Typography>
            </FlexColBox>

            <Divider />

            <FlexColBox gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}>
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <FlexColBox
                  gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}
                >
                  <Typography variant="textRegularBold">
                    {t("general.design")}
                  </Typography>
                  <Typography variant="textSm" color={palette.grey.primary}>
                    {t("pricing.creditsUsed", {
                      usedCredits: designPricing.creditsUsedInDesign,
                      extraCredits: creditUnitPrice.extraCredits,
                    })}
                  </Typography>
                </FlexColBox>
                <Typography variant="textRegularBold">
                  {creditUnitPrice.formatted}
                </Typography>
              </Box>
              <FlexColBox
                gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
                ml={"8px"}
              >
                {formattedCredits.map((credit) => (
                  <Box
                    key={credit.id}
                    display="flex"
                    gap={"4px"}
                    alignItems={"center"}
                  >
                    <Box display="flex" gap={"8px"} alignItems={"center"}>
                      <Icon
                        icon="circle"
                        filled
                        color={palette.grey.primary}
                        size={4}
                      />
                      <Typography variant="textSm" color={palette.grey.primary}>
                        {credit.name}
                      </Typography>
                    </Box>
                    <ChipCredit>
                      {t("general.credits", { count: credit.credits })}
                    </ChipCredit>
                  </Box>
                ))}
              </FlexColBox>
            </FlexColBox>

            <Divider />

            {hasMinimum && !hasReachedMaximum && (
              <>
                <Box sx={styles.bottomSection}>
                  <Typography variant="textRegularBold">
                    {t("pricing.unitPrice")}
                  </Typography>
                  <Typography variant="textRegularBold">
                    {unitPrice.formatted}
                  </Typography>
                </Box>
                <Divider />
                <FlexColBox
                  gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}
                >
                  <Box sx={styles.bottomSection}>
                    <Typography variant="textRegularBold">
                      {t("pricing.subtotal")}
                    </Typography>
                    <Typography variant="textRegularBold">
                      {totalPrice.formatted}
                    </Typography>
                  </Box>
                  <LanguageSwitcher />
                </FlexColBox>
                <Divider />
                <Box sx={styles.bottomSection}>
                  <Typography variant="textRegularBold">
                    {t("pricing.shippingAndTaxes")}
                  </Typography>
                  <Typography variant="textRegularBold">
                    {t("pricing.calculateAtCheckout")}
                  </Typography>
                </Box>
                <Divider />
              </>
            )}
          </FlexColBox>
        </FlexColBox>
      </DialogContent>
    </Dialog>
  );
};
