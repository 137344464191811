import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MultiplayerCursor from "../../common/components/MultiplayerCursor";
import { useSelector } from "../../common/hooks/useSelector";
import ProductImage from "../../components/ProductImage";
import { ProductModal } from "../../components/ProductModal";
import StudioToolbar from "../../components/StudioToolbar";
import { AppLoadingContext } from "../../context";
import DesignService from "../../service/DesignService";
import DesignStateService from "../../service/DesignStateService";
import { DesignFamily, DesignProduct } from "../../types";
import { ROUTES } from "../../utils/Constant";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
import {
  useCursorPointerWebsocket,
  useDesignState,
} from "../../utils/ReactHooks";
import StudioLeftSidebar from "../StudioSidebarContainer/StudioLeftSidebar";
import StudioRightSidebar from "../StudioSidebarContainer/StudioRightSidebar";
import ProjectColumn from "./ProjectColumn";
import styles from "./StudioContainer.module.scss";

export const StudioContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { designId } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isAppLoading, setIsAppLoading } = useContext(AppLoadingContext);

  //The projects, Designs and UserInfo are put in the store (eventually because async) by App.js)
  const projectsState = useSelector((state) => state.projects);
  const projects = useMemo(() => {
    return projectsState?.allProjects;
  }, [projectsState]);

  const teamsState = useSelector((state) => state.myTeams);
  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const designsReduxState = useSelector((state) => state.designs);
  const designs = useMemo(() => {
    return designsReduxState?.designs;
  }, [designsReduxState]);

  const designsLoaded = useMemo(() => {
    return designsReduxState?.loaded;
  }, [designsReduxState]);

  const userInfo = useSelector((state) => state.userInfo);

  const [adding, setAdding] = useState(false);

  /**
   * selectedDesignInProject object is different from the design object from DesignState microservice.
   * selectedDesignInProject comes from the the backend server microservice.
   */
  const selectedDesignInProject = useMemo(() => {
    return designs?.find((d) => d.id === designId);
  }, [designs, designId]);

  const project = useMemo(() => {
    if (!selectedDesignInProject) {
      return;
    }
    return projects.find((p) => p.id === selectedDesignInProject.project_id);
  }, [projects, selectedDesignInProject]);

  const team = useMemo(() => {
    if (!project) {
      return;
    }
    return teams.find((t) => t.id === project.team_id);
  }, [teams, project]);

  //DP TODO: The websocket is in useCursorPointerWebsocket, we should extract it into it's own component
  const {
    cursorsOfUsersConnected,
    previewContainerRef,
    usersConnected,
    socketRef,
  } = useCursorPointerWebsocket({
    teammates: team?.teammates ?? [],
    designId,
    userId: userInfo.id,
  });

  const {
    designDecorationsFromDesignState,
    images,
    designWarning,
    migrationNeeded,
    colorWarning,
    deprecatedDesignItems,
    colorBleedingItems,
    isLoading,
    isUpdating,
    hasLoadingFailed,
    loadingFailedErrorKey,
    onDesignChange,
    onAssetTypeChange,
    partsDefinition,
    availableDecorationsDefinition,
    designValueOfSelectedElement,
    selectedPartElementDef,
    designFromDesignState,
    designPricing,
    deliveryTime,
    siblingProducts,
    pricingDef,
    sizesOption,
    selection,
    onSelectionChange,
  } = useDesignState(socketRef, designId);

  const productName = selectedDesignInProject?.design?.product.name;
  const designName = selectedDesignInProject?.name;
  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();

  //TODO: This is the same function as in the DesignsContainer
  //So must find a way to not duplicate the same code
  const createDesign = async (
    family: DesignFamily,
    product: DesignProduct,
    newDesignName: string
  ) => {
    setIsAppLoading(true);
    try {
      const res = await DesignStateService.createDesignState({
        family,
        product,
      });

      const {
        data: { designId },
      } = res;

      const projectId = project?.id;

      await DesignService.addDesign(
        {
          projectId,
          designId,
          name: newDesignName,
        },
        product
      );

      await fetchMyDesignsAndUpdateStore(projectId);

      navigate(generatePath(ROUTES.STUDIO, { designId, projectId }));

      setAdding(false);
      setIsAppLoading(false);
    } catch (error) {
      console.error(error);
      setIsAppLoading(false);
    }
  };

  //If design is not found in backend database, then we go home
  useEffect(() => {
    if (designsLoaded && !selectedDesignInProject) {
      toast.warn(t("design.not_found"));
      navigate(ROUTES.HOME);
      return;
    }
  }, [designsLoaded, selectedDesignInProject, navigate, t]);

  //If loading has failed from designState, we go to home page and let user know there was an issue
  useEffect(() => {
    if (hasLoadingFailed) {
      toast.error(t(`toast.${loadingFailedErrorKey}`), {
        toastId: loadingFailedErrorKey,
      });
      navigate(ROUTES.HOME);
      return;
    }
  }, [hasLoadingFailed, loadingFailedErrorKey, t, navigate]);

  useEffect(() => {
    if (isLoading || !project) {
      setIsAppLoading(true);
    } else {
      setIsAppLoading(false);
    }
  }, [isLoading, project, setIsAppLoading]);

  useEffect(() => {
    if (containerRef) {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [containerRef]);

  return (
    <div className={styles.container} ref={containerRef}>
      <Helmet>
        <title>{`${t("pages.design.title", {
          id: designName /*t("products." + productName + ".name")*/,
        })}`}</title>
      </Helmet>
      <div style={{ gridArea: "header" }}>
        <StudioToolbar
          className={styles.studioToolBar}
          project={project}
          users={usersConnected}
          designFromProject={selectedDesignInProject}
          productName={designFromDesignState?.product.name}
          designPricing={designPricing}
          pricingDef={pricingDef}
          sizesOption={sizesOption}
          userInfo={userInfo}
          canRenameDesign={userInfo.canRenameDesign}
          canDeleteDesign={userInfo.canDeleteDesign}
          deliveryTime={deliveryTime}
        />
      </div>
      <div style={{ gridArea: "projects" }}>
        <ProjectColumn
          onAddButtonClick={() => setAdding(true)}
          projectId={project?.id}
          selectedDesignId={designId}
          canAddDesign={userInfo.canAddDesign}
        />
      </div>
      <div className={styles.designContainer}>
        <React.Fragment>
          {cursorsOfUsersConnected.map((c) => (
            <MultiplayerCursor
              previewContainerRef={previewContainerRef}
              xCoordinates={c.coordinates.x}
              yCoordinates={c.coordinates.y}
              color={c.color}
              key={c.userId}
            />
          ))}
          <StudioLeftSidebar
            decorations={designDecorationsFromDesignState}
            designId={designId}
            productName={designFromDesignState?.product.name}
            partsDefinition={partsDefinition}
            availableDecorationsDefinition={availableDecorationsDefinition}
            onDesignChange={onDesignChange}
            onSelectionChange={onSelectionChange}
            designFromDesignState={designFromDesignState}
            selection={selection}
            canUpdateArtworkPosition={userInfo.canUpdateDesignStyles}
            canAddArtwork={userInfo.canAddArtwork}
            canDeleteArtwork={userInfo.canDeleteArtwork}
            deprecatedDesignItems={deprecatedDesignItems}
            colorBleedingItems={colorBleedingItems}
            siblingProducts={siblingProducts}
          />
          <ProductImage
            images={images}
            previewContainerRef={previewContainerRef}
            isUpdating={isUpdating}
            designWarning={designWarning}
            migrationNeeded={migrationNeeded}
            colorWarning={colorWarning}
            onDesignChange={onDesignChange}
            decorations={designDecorationsFromDesignState}
          />
          <StudioRightSidebar
            designId={designId}
            productName={productName}
            teamId={project?.team_id}
            onDesignChange={onDesignChange}
            onAssetTypeChange={onAssetTypeChange}
            designValueOfSelectedElement={designValueOfSelectedElement}
            selectedPartElementDef={selectedPartElementDef}
            selection={selection}
            decorations={designDecorationsFromDesignState}
            availableDecorationsDefinition={availableDecorationsDefinition}
            isUpdating={isUpdating}
            canUpdateDesignStyles={userInfo.canUpdateDesignStyles}
            canUpdateDesignColors={userInfo.canUpdateDesignColors}
            canUpdateArtworkPosition={userInfo.canUpdateArtworkPosition}
            canUpdateArtworkColor={userInfo.canUpdateArtworkColor}
            deprecatedDesignItems={deprecatedDesignItems}
            colorBleedingItems={colorBleedingItems}
            isAdmin={userInfo.isAdmin || userInfo.isDesigner}
          />
        </React.Fragment>
      </div>

      <ProductModal
        onCloseModal={() => setAdding(false)}
        createDesign={createDesign}
        open={adding && !isAppLoading}
      />
    </div>
  );
};
