import { memo, PropsWithChildren } from "react";
import { Box } from "..";
import { palette, Styles } from "../../../styles/theme";

const styles: Styles = {
  container: {
    display: "flex",
    maxWidth: "fit-content",
    padding: "1px 6px 2px 6px",
    alignItems: "center",
    gap: "2px",
    borderRadius: "100px",
    border: "1px solid transparent",
    background: `linear-gradient(90deg, rgba(84, 172, 222, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%), ${palette.lightBorderGradient}`,
    "&>*": {
      fontFamily: "Apercu Pro",
      fontSize: "8px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
      background: "linear-gradient(90deg, #54ACDE 0%, #A954DE 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
};

const GradientChip = (props: PropsWithChildren) => {
  return (
    <Box sx={styles.container}>
      <span>{props.children}</span>
    </Box>
  );
};

export default memo(GradientChip);
