import { Modal, Typography, Zoom } from "@mui/material";
import { uniq } from "lodash";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, ChipProps, FlexColBox, Icon } from "../../common/components";
import { palette, spacing, Styles } from "../../styles/theme";
import {
  DesignFamily,
  DesignProduct,
  ProductFamily,
  ProductLineType,
} from "../../types";
import {
  ProductFamilyList,
  ProductLinesToColor,
  ProductLinesToIcon,
  ProductList,
} from "../../utils/Constant";
import {
  PRODUCT_CARD_MIN_WIDTH,
  ProductFamilyModalItem,
} from "./components/ProductFamilyModalItem";
import { ProductModalItem } from "./components/ProductModalItem";

const styles: Styles = {
  container: {
    p: { xs: spacing.wrapperMobile, lg: spacing.largeDesktop },
    pb: { xs: spacing.xlMobile, lg: spacing.largeDesktop },
    background: palette.white,
    minHeight: "100vh",
    maxHeight: "100vh",
    overflowY: "auto",
    gap: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
  },
  productListContainer: {
    display: "grid",
    gridTemplateColumns: {
      xs: `repeat(auto-fill,minmax(${PRODUCT_CARD_MIN_WIDTH}, 1fr))`,
      lg: `repeat(auto-fill,minmax(${PRODUCT_CARD_MIN_WIDTH}, 1fr))`,
    },
    gap: { xs: spacing.smallMobile, lg: spacing.smallDesktop },
  },
};

const FilterList: Omit<ChipProps<ProductLineType>, "onClick">[] = [
  {
    id: ProductLineType.HYPER_CUSTOM,
    color: ProductLinesToColor[ProductLineType.HYPER_CUSTOM],
    labelKey: "products.line.hyperCustom.title",
    icon: ProductLinesToIcon[ProductLineType.HYPER_CUSTOM],
  },
  {
    id: ProductLineType.ECO,
    color: ProductLinesToColor[ProductLineType.ECO],
    labelKey: "products.line.eco.title",
    icon: ProductLinesToIcon[ProductLineType.ECO],
  },
  {
    id: ProductLineType.CLASSIC,
    color: ProductLinesToColor[ProductLineType.CLASSIC],
    labelKey: "products.line.classics.title",
    icon: ProductLinesToIcon[ProductLineType.CLASSIC],
  },
];

interface Props {
  open: boolean;
  onCloseModal: () => void;
  createDesign: (
    family: DesignFamily,
    product: DesignProduct,
    labelTag: string
  ) => void;
}

const ProductModal = ({ createDesign, onCloseModal, open }: Props) => {
  const { t } = useTranslation();

  const [filterBy, setFilterBy] = useState<ProductLineType>();
  const [selectedFamily, setSelectedFamily] = useState<ProductFamily>();

  const onFilterChange = (id: ProductLineType) => {
    setFilterBy((prev) => (prev === id ? undefined : id));
  };

  const filteredProductList = ProductList.filter(
    (p) =>
      (filterBy ? p.line === filterBy : true) &&
      (selectedFamily ? p.family.name === selectedFamily.id : true)
  );

  const availableLine = useMemo(() => {
    if (!selectedFamily) {
      return [
        ProductLineType.HYPER_CUSTOM,
        ProductLineType.ECO,
        ProductLineType.CLASSIC,
      ];
    }

    return uniq(
      ProductList.filter((p) =>
        selectedFamily ? p.family.name === selectedFamily.id : true
      ).map((p) => p.line)
    );
  }, [selectedFamily]);

  const handleCreateDesign = (
    family: DesignFamily,
    product: DesignProduct,
    labelTag: string
  ) => {
    setFilterBy(undefined);
    setSelectedFamily(undefined);
    createDesign(family, product, labelTag);
  };

  const handleClose = () => {
    setFilterBy(undefined);
    setSelectedFamily(undefined);
    onCloseModal();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Zoom in={open} style={{ transitionDelay: open ? "200ms" : "0ms" }}>
        <FlexColBox sx={styles.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            fullWidth
          >
            {selectedFamily ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
              >
                <Icon
                  icon="chevron_left"
                  size={28}
                  onClick={() => setSelectedFamily(undefined)}
                />
                <Typography variant="title">
                  {t(selectedFamily.labelTag)}
                </Typography>
              </Box>
            ) : (
              <Typography variant="title">
                {t("products.modal.select_canvas")}
              </Typography>
            )}
            <Icon
              onClick={handleClose}
              icon="close"
              color={palette.grey.nearBlack}
              size={28}
            />
          </Box>
          {/* <FlexColBox
            gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
          >
            <Typography
              color={palette.grey.nearBlack}
              variant="textRegularBold"
            >
              {t("general.filterBy")}
            </Typography>

            <Box display={"flex"} alignItems={"center"} gap={"8px"}>
              {FilterList.map((line) => (
                <Chip
                  key={line.id}
                  {...line}
                  id={line.id}
                  onClick={onFilterChange}
                  active={filterBy === line.id}
                  disabled={!availableLine.includes(line.id)}
                  containerSx={{ p: "16px" }}
                  iconSize={29}
                  description={t(`products.line.${line.id}.description`)}
                />
              ))}
            </Box>
          </FlexColBox> */}
          <Box sx={styles.productListContainer}>
            {selectedFamily
              ? filteredProductList.map((product) => (
                  <ProductModalItem
                    product={product}
                    onCreateDesign={handleCreateDesign}
                    key={product.product.name}
                    dataTest={`selectCanvas.product.${product.product.name}`}
                    isFiltering={!!filterBy}
                  />
                ))
              : ProductFamilyList.filter((f) =>
                  filterBy ? f.lines.includes(filterBy) : true
                ).map((family) => (
                  <ProductFamilyModalItem
                    family={family}
                    onFamilySelect={() => setSelectedFamily(family)}
                    key={family.id}
                    dataTest={`selectCanvas.family.${family.id}`}
                  />
                ))}
          </Box>
        </FlexColBox>
      </Zoom>
    </Modal>
  );
};

export default memo(ProductModal);
