import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCurrency } from "../../utils/Currencies";
import DeleteModal from "../DeleteModal/DeleteModal";
import PurchasedItemSizesDetails from "../PurchasedItemSizesDetails";
import styles from "./purchasedtemDetails.module.scss";

//TODO: ingest in props the sizesDefinition to get the label key for each sizes (instead of using the size.name)
const PurchasedItemDetails = (props) => {
  const {
    onGoToEditPurchasedDesign = null,
    onRemoveDesign = null,
    onUpdateSizesQty = null,
    title,
    purchasedItem,
  } = props;

  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteClickClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowDeleteModal(false);
  };

  const { getCurrencyFormatter } = useCurrency();
  const designState = purchasedItem.designState;
  const images = purchasedItem.designState.images;
  const currency = purchasedItem.pricing.currency;
  const currencyFormatter = getCurrencyFormatter(currency);

  //const designWithColors = purchasedItem.designState.designWithColors;
  const designCredits =
    purchasedItem.designState.customizationPricing.creditsUsedInDesign;

  /**
   *
   */
  const onClickEditDesign = useCallback(
    (e) => {
      e.preventDefault();

      return onGoToEditPurchasedDesign(purchasedItem.designState.designId);
    },
    [purchasedItem.designState.designId, onGoToEditPurchasedDesign]
  );

  /**
   *
   */
  const onClicRemovePurchasedItem = useCallback(() => {
    return onRemoveDesign(purchasedItem.designId);
  }, [purchasedItem.designId, onRemoveDesign]);

  return (
    <div className={styles.container}>
      <div className={styles.generalInfo}>
        <div className={styles.imgContainer}>
          {images.map((image) => (
            <img
              src={image.url}
              alt={image.side}
              className={styles.img}
              key={image.side}
            />
          ))}
        </div>
        <div className={styles.nameAndDescription}>
          <div className={styles.orderTitle}>
            <span>{title}</span>
          </div>
          <div className={styles.actions}>
            {onGoToEditPurchasedDesign && (
              <Link to="" onClick={(e) => onClickEditDesign(e)}>
                {t("techpack.edit_design")}
              </Link>
            )}{" "}
            {onRemoveDesign && (
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteModal(true);
                }}
              >
                {t("techpack.remove_design")}
              </Link>
            )}
          </div>
          <div className={styles.priceContainer}>
            <table>
              <tbody>
                <tr className={styles.borderBottom}>
                  <td>{t(`techpack.creditsUsed`)}:</td>
                  <td className={styles.alignRight}>{designCredits}</td>
                </tr>
                <tr>
                  <td>
                    {t(`techpack.priceWithoutCreditsNorShippingPerItem`)}:{" "}
                  </td>
                  <td className={styles.alignRight}>
                    {purchasedItem.pricing.hasReachedMaximum
                      ? t("order.tbd")
                      : (purchasedItem.pricing.pricePerUnit &&
                          currencyFormatter.format(
                            purchasedItem.pricing.pricePerUnit -
                              purchasedItem.pricing.priceOfExtraCreditsPerUnit
                          )) ||
                        "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(`techpack.priceCreditsPerItem`)} (
                    {purchasedItem.pricing.extraCredits}):{" "}
                  </td>
                  <td className={styles.alignRight}>
                    {(purchasedItem.pricing.priceOfExtraCreditsPerUnit &&
                      currencyFormatter.format(
                        purchasedItem.pricing.priceOfExtraCreditsPerUnit
                      )) ||
                      "N/A"}
                  </td>
                </tr>
                <tr className={styles.borderBottom}>
                  <td>
                    <strong>{t(`techpack.total_per_unit`)}: </strong>
                  </td>
                  <td className={styles.alignRight}>
                    <strong>
                      {purchasedItem.pricing.hasReachedMaximum
                        ? t("order.tbd")
                        : purchasedItem.pricing.pricePerUnit &&
                          currencyFormatter.format(
                            purchasedItem.pricing.pricePerUnit
                          )}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{t(`techpack.shippingPricePerItem`)}: </td>
                  <td className={styles.alignRight}>
                    {purchasedItem.pricing.hasReachedMaximum
                      ? t("order.tbd")
                      : (purchasedItem.pricing.priceOfShippingPerUnit &&
                          currencyFormatter.format(
                            purchasedItem.pricing.priceOfShippingPerUnit
                          )) ||
                        "N/A"}
                  </td>
                </tr>
                <tr className={styles.borderBottom}>
                  <td>
                    <strong>
                      {t(`techpack.total_per_unit_with_shipping`)}:{" "}
                    </strong>
                  </td>
                  <td className={styles.alignRight}>
                    <strong>
                      {purchasedItem.pricing.hasReachedMaximum
                        ? t("order.tbd")
                        : purchasedItem.pricing.pricePerUnit &&
                          currencyFormatter.format(
                            purchasedItem.pricing.priceOfShippingPerUnit +
                              purchasedItem.pricing.pricePerUnit
                          )}
                    </strong>
                  </td>
                </tr>
                <tr className={styles.borderBottom}>
                  <td>
                    <strong>{t(`techpack.priceForExtraSize`)}: </strong>
                  </td>
                  <td className={styles.alignRight}>
                    <strong>
                      {(purchasedItem.pricing.priceOfExtraSizes &&
                        currencyFormatter.format(
                          purchasedItem.pricing.priceOfExtraSizes
                        )) ||
                        "N/A"}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={styles.customizations}>
            <span className={styles.creditUsedTitle}>
              {t("pricing.credits_used_design", {
                creditsUsed:
                  designState.customizationPricing.creditsUsedInDesign,
              })}
              {/*TODO Make the translation plurial and singular check https://react.i18next.com/latest/trans-component#using-with-react-components*/}
            </span>
            <div className={styles.creditUsedList}>
              <ul>
                {designState.customizationPricing
                  .customizationsListWithCredits &&
                  designState.customizationPricing.customizationsListWithCredits.map(
                    (item, index) => (
                      <li key={`item-${item.name}-${index}`}>
                        {t("pricing.credits_used_feature", {
                          customizationName:
                            item.type === "ARTWORK"
                              ? t(
                                  `specs.decoration_types.${item.displayLabels[0]}.title`
                                ) +
                                " - " +
                                item.displayLabels[1] +
                                " - " +
                                t(`positions.${item.displayLabels[2]}`)
                              : item.type === "Optional layer"
                                ? t(
                                    `specs.${designState.product.name}.${item.displayLabels[0]}`
                                  ) +
                                  " - " +
                                  t(
                                    `specs.${designState.product.name}.${item.displayLabels[1]}`
                                  ) +
                                  " - " +
                                  t(
                                    `specs.${designState.product.name}.${item.displayLabels[2]}`
                                  ) +
                                  " - " +
                                  t(
                                    `specs.${designState.product.name}.${item.displayLabels[3]}`
                                  )
                                : item.type === "Style"
                                  ? t(
                                      `specs.${designState.product.name}.${item.displayLabels[0]}`
                                    ) +
                                    " - " +
                                    t(
                                      `specs.${designState.product.name}.${item.displayLabels[1]}`
                                    ) +
                                    " - " +
                                    t(
                                      `specs.${designState.product.name}.${item.displayLabels[2]}`
                                    )
                                  : item.type === "First color"
                                    ? t(`colors.${item.displayLabels[0]}`)
                                    : item.type === "Extra color"
                                      ? t(`colors.${item.displayLabels[0]}`)
                                      : item.displayLabels.join(" - "),
                          creditsCount: item.credits,
                        })}
                      </li>
                    )
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <PurchasedItemSizesDetails
        sizes={purchasedItem.sizes}
        onUpdateSizesQty={
          !onUpdateSizesQty
            ? null
            : (sizes) => {
                return onUpdateSizesQty(purchasedItem.design_id, sizes);
              }
        }
      />
      <DeleteModal
        open={showDeleteModal}
        onDelete={onClicRemovePurchasedItem}
        title={t("preorder.remove_item_modal", {
          project_name: purchasedItem.projectName,
          design_name: purchasedItem.designName,
        })}
        message={t("project.remove_menu")}
        onClose={onDeleteClickClose}
      />
    </div>
  );
};

export default PurchasedItemDetails;
