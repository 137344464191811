import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import ColorCircle from "../ColorCircle";

import styles from "./ValueContainer.module.scss";

const ColorValueContainer = ({ children, ...props }) => {
  const { bleedingWarning } = props;
  const { t } = useTranslation();
  const [colorCode = { value: 604 }] = useMemo(
    () => props.getValue() || [],
    [props]
  );

  const active = props.selectProps.active;

  const color = useMemo(
    () => props.options.find((c) => colorCode.value === c.value),
    [colorCode, props.options]
  );

  return (
    <components.ValueContainer {...props}>
      <div className={styles.container}>
        {active && color && (
          <React.Fragment>
            <ColorCircle
              color={color.hexCode}
              colors={color.colorsHex}
              png={color.png}
              label={color.label}
            />
            <span className={styles.colorText}>
              {color.label ? t(`colors.${color.label}`) : ""}
            </span>
            {color?.isDeprecated && (
              <span className={styles.deprecatedValueDot}></span>
            )}
            {bleedingWarning && (
              <span className={styles.colorBleedingWarningDot}></span>
            )}
            <div style={{ flex: 1 }} />
            {children[1]}
          </React.Fragment>
        )}
        {active && !color && (
          // This is a custom color
          <React.Fragment>
            <ColorCircle color={props.getValue()[0].value} />
            <span className={styles.colorText}>{t(`colors.custom`)}</span>
            {color?.isDeprecated && (
              <span className={styles.deprecatedValueDot}></span>
            )}
            {bleedingWarning && (
              <span className={styles.colorBleedingWarningDot}></span>
            )}
            <div style={{ flex: 1 }} />
            {children[1]}
          </React.Fragment>
        )}

        {!active && (
          <React.Fragment>
            <span className={styles.colorText}>{t(`colors.not_active`)}</span>
            <div style={{ flex: 1 }} />
            {children[1]}
          </React.Fragment>
        )}
      </div>
    </components.ValueContainer>
  );
};

export default ColorValueContainer;
