import { useTranslation } from "react-i18next";
import ColorCircle from "../../../../common/components/Select/ColorSelect/ColorCircle";
import styles from "./techpackCustomColorForm.module.scss";

const TechpackCustomColor = (props) => {
  const { customColor, onTeckpackColorReferenceUpdate } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.colorSection}>
        <ColorCircle color={customColor.hex} label={customColor.label} />
        <div className={styles.colorColumn}>
          <span className={styles.fabricDescription}>
            {t(`fabricName.${customColor.fabricName}`)} -{" "}
            {customColor.fabricDescription}
          </span>
          <span className={styles.hexColor}>{customColor.hex}</span>
        </div>
      </div>
      <div className={styles.listOfParts}>
        {customColor.usedOnTechpackPositions.map((item, index) => (
          <div key={index} className={styles.partItem}>
            <span className={styles.arrow}>▶</span> {/* Gray arrow */}
            {item}
          </div>
        ))}
      </div>
      <div className={styles.techpackInputColumn}>
        <span>Reference for Techpack</span>
        <input
          type={"text"}
          defaultValue={customColor.techpackColorReference}
          placeholder={"i.e. PT1335"}
          onChange={(e) =>
            onTeckpackColorReferenceUpdate(customColor.id, e.target.value)
          }
        />
      </div>
    </div>
  );
};

export default TechpackCustomColor;
