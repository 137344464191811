import { Theme, Typography, useTheme } from "@mui/material";
import { createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box, Label } from "../../../common/components";
import { palette, spacing } from "../../../styles/theme";
import { ProductFamily } from "../../../types";

export const PRODUCT_CARD_MIN_WIDTH = "165px";

const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      pt: { xs: spacing.smallMobile, lg: spacing.smallDesktop },
      pb: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "214px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        px: 2,
      },
      borderRadius: "12px",

      border: `2px solid transparent`,
      "&:hover": {
        border: `2px solid ${palette.grey.lightGrey}`,
      },
      ":active": {
        border: `2px solid ${palette.blue.secondary}`,
      },
    },
    itemContainer: {
      "&>img": {
        minWidth: "150px",
        maxWidth: "150px",
        maxHeight: "150px",
        verticalAlign: "middle",
      },
    },
    itemDescriptionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  });

interface Props {
  family: ProductFamily;
  onFamilySelect: () => void;
  dataTest: string;
}

export const ProductFamilyModalItem = (props: Props) => {
  const {
    family: { imgName, comingSoon, labelTag, lines },
    onFamilySelect,
    dataTest,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box
      data-test={dataTest}
      sx={styles.container}
      onClick={!comingSoon ? onFamilySelect : undefined}
    >
      <Box sx={styles.itemContainer}>
        <img src={imgName} alt={t(labelTag)} />
      </Box>
      <Box
        sx={styles.itemDescriptionContainer}
        gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
      >
        <Typography textAlign="center" variant="h5">
          {t(labelTag)}
        </Typography>
        {/* {!comingSoon && (
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            {lines.map((line) => (
              <ProductLine line={line} key={line} />
            ))}
          </Box>
        )} */}
        {comingSoon && (
          <Label sx={{ textAlign: "center", fontSize: 12 }}>
            {t("products.modal.coming")}
          </Label>
        )}
      </Box>
    </Box>
  );
};
