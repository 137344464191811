import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, FlexColBox, ImageSlider } from "../../common/components";
import CartItemSizeSimplified from "../../containers/Cart/CartItemSizeSelector/CartItemSizeSimplified";
import { palette, spacing, Styles } from "../../styles/theme";
import { CartItem } from "../../types";
import { ROUTES } from "../../utils/Constant";
import { useThemeBreakpoints } from "../../utils/themeUtils";

const styles: Styles = {
  sizeContainer: {
    display: "grid",
    gridTemplateColumns: {
      xs: `repeat(auto-fill,minmax(48px, 1fr))`,
      lg: `repeat(auto-fill,minmax(48px, 1fr))`,
    },
    gap: {
      xs: spacing.smallMobile,
      lg: spacing.smallDesktop,
    },
  },
};

interface Props {
  item: CartItem;
}

const OrderItem = ({ item }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  const navigate = useNavigate();
  const isSocks = item.productName?.includes("Socks");

  return (
    <FlexColBox gap={spacing.regularMobile}>
      <Box
        display={"flex"}
        gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
      >
        <ImageSlider
          images={item.productImages}
          containerWidth={isMobile ? "72px" : "150px"}
          containerHeight={isMobile ? "84px" : "176px"}
          onClick={() =>
            navigate(
              generatePath(ROUTES.STUDIO, {
                projectId: item.projectId,
                designId: item.designId ?? item.design_id,
              })
            )
          }
        />

        <FlexColBox
          gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
          width={"100%"}
        >
          <FlexColBox
            gap={{ xs: spacing.subtitleMobile, lg: spacing.subtitleDesktop }}
          >
            <Typography
              data-test={item.designName}
              variant="h2"
              color={palette.grey.nearBlack}
            >
              {item.designName || t("design.untitled_design")}
            </Typography>
            <Typography variant="textRegular" color={palette.grey.primary}>
              {t(`checkout.quantityOnly.${isSocks ? "socks" : "others"}`, {
                count: item.pricing.qty,
              })}
            </Typography>
          </FlexColBox>
          {!isMobile && (
            <Box sx={styles.sizeContainer}>
              {item.sizes?.map((size, index) => (
                <CartItemSizeSimplified
                  key={`${size.name} ${index}`}
                  itemSize={size}
                  greyedOutInputColor
                  disabled
                />
              ))}
            </Box>
          )}
        </FlexColBox>
      </Box>
      {isMobile && (
        <Box sx={styles.sizeContainer}>
          {item.sizes?.map((size, index) => (
            <CartItemSizeSimplified
              key={`${size.name} ${index}`}
              itemSize={size}
              greyedOutInputColor
              disabled
            />
          ))}
        </Box>
      )}
    </FlexColBox>
  );
};

export default OrderItem;
