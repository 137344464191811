import { Typography } from "@mui/material";
import { ChangeEvent, memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, ImageSlider } from "../../../common/components";
import { CART_ACTION_ENUM } from "../../../common/reducers/cart";
import { CalculatePriceModal } from "../../../components/CalculatePriceModal/CalculatePriceModal";
import config from "../../../config";
import { palette, spacing, Styles } from "../../../styles/theme";
import { CartItem, Size } from "../../../types";
import { ROUTES } from "../../../utils/Constant";
import { useCurrency } from "../../../utils/Currencies";
import { useDesignState } from "../../../utils/ReactHooks";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import CartItemNameAndAction from "./CartItemNameAndAction";
import CartItemSizeSimplified from "./CartItemSizeSimplified";

const styles: Styles = {
  sizeContainer: {
    display: "grid",
    gridTemplateColumns: {
      xs: `repeat(auto-fill,minmax(48px, 1fr))`,
      lg: `repeat(auto-fill,minmax(48px, 1fr))`,
    },
    gap: {
      xs: spacing.smallMobile,
      lg: spacing.smallDesktop,
    },
  },
  generalInfo: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    alignItems: "start",
    padding: { xs: spacing.wrapperMobile, lg: spacing.wrapperDesktop },
    gap: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
  },
  imgContainer: {
    display: "flex",
    alignItems: "flex-start",
    mr: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
    gap: { xs: spacing.mediumMobile, lg: undefined },
  },
  bundleHeader: {
    p: spacing.groupingMobile,
    borderRadius: "4px 4px 0 0",
    background: palette.grey.lightBackground,
  },
};

interface Props {
  item: CartItem;
  isBundle?: boolean;
  onChange: () => void;
  onRemove: (id: string, productName: string) => void;
}

const CartItemSizeSelectorContent = (props: Props) => {
  const {
    onChange,
    onRemove,
    isBundle,
    item: {
      deprecatedDesign: deprecatedDesignFromCartItem,
      designName,
      id,
      pricing,
      productImages,
      productName,
      designId,
      designState: { customizationPricing },
      sizes,
      projectId,
    },
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isMobile } = useThemeBreakpoints();

  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const { pricingDef, sizesOption } = useDesignState(undefined, designId);

  /**
   * Because the onChange will most likely be throttled and
   * the call to update sizes will be followed by a get of updated items,
   * We must use a local size that will be updated on size change (for the user to get feedback)
   * and when the get items will return (thus sizes from props will be updated), the local values will be updated if the backend didn't update to what the customer asked.
   * That way what will be displayed is at one point what the customer wants it to be and after what the server has stored
   */
  const [sizesQty, setSizesQty] = useState(sizes);

  const onSizeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    size: Size
  ) => {
    const amount = Number(e.target.value) || 0;

    setSizesQty((prev) => {
      const newSizesQty = prev.map((x) =>
        x.name === size.name ? { ...x, qty: amount } : x
      );
      return newSizesQty;
    });

    dispatch({
      type: CART_ACTION_ENUM.UPDATE_ITEM_SIZES,
      payload: {
        id,
        size,
        amount,
      },
    });

    onChange();
  };

  const { dispatch } = useStore();

  const { NarrowCurrencyFormatter } = useCurrency();

  const deprecatedDesign = useMemo(() => {
    return (
      (deprecatedDesignFromCartItem &&
        config.blockPaymentWhenDesignIsDeprecated) ||
      false
    );
  }, [deprecatedDesignFromCartItem]);

  const Price = () => (
    <Typography
      variant="textRegular"
      color={palette.blue.secondary}
      display="block"
      minHeight={{ xs: undefined, lg: 17 }}
      width={"fit-content"}
      onClick={() => setIsPriceModalOpen(true)}
      sx={{
        cursor: "pointer",
        ":hover": {
          textDecoration: "underline",
        },
      }}
    >
      {!deprecatedDesign &&
        pricing.hasMinimum &&
        pricing.hasMinimumPerSize &&
        (pricing.hasReachedMaximum
          ? t("checkout.goingbig")
          : NarrowCurrencyFormatter?.format(pricing.pricePerUnit))}
    </Typography>
  );

  return (
    <Box sx={styles.generalInfo}>
      <Box sx={styles.imgContainer}>
        <ImageSlider
          images={productImages}
          containerWidth={isMobile ? "72px" : "150px"}
          containerHeight={isMobile ? "84px" : "176px"}
          onClick={() =>
            navigate(generatePath(ROUTES.STUDIO, { projectId, designId }))
          }
        />
        {isMobile && (
          <CartItemNameAndAction
            deprecatedDesign={deprecatedDesign}
            designName={designName}
            onRemove={() => onRemove(id, productName)}
            pricing={pricing}
            isBundle={isBundle}
            productName={productName}
            designId={designId}
            designPricing={customizationPricing}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
      >
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="column"
            gap={{ xs: spacing.subtitleMobile, lg: spacing.subtitleDesktop }}
          >
            <CartItemNameAndAction
              deprecatedDesign={deprecatedDesign}
              designName={designName}
              onRemove={() => onRemove(id, productName)}
              pricing={pricing}
              productName={productName}
              designId={designId}
              designPricing={customizationPricing}
            />
            <Price />
          </Box>
        )}
        <Box display="flex" sx={styles.sizeContainer}>
          {sizesQty?.map((size, index) => (
            <CartItemSizeSimplified
              key={`${size.name} ${index}`}
              itemSize={size}
              onSizeChange={onSizeChange}
            />
          ))}
        </Box>
      </Box>
      {!!pricingDef && !!sizesOption && !isMobile && (
        <CalculatePriceModal
          open={isPriceModalOpen}
          onClose={() => setIsPriceModalOpen(false)}
          designId={designId}
          productName={productName}
          designPricing={customizationPricing}
          pricingDef={pricingDef}
          sizesOption={sizesOption}
        />
      )}
    </Box>
  );
};

export default memo(CartItemSizeSelectorContent);
