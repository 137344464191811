import React from "react";

import styles from "./MicroColorCircle.module.scss";

import { useTranslation } from "react-i18next";
import DarkTooltip from "../../DarkTooltip";

interface Props {
  color: string;
  png?: string;
  isSelected: boolean;
  tooltip: string;
}

export const MicroColorCircle = ({
  color,
  png,
  isSelected,
  tooltip,
}: Props) => {
  const { t } = useTranslation();

  let colorCircle = null;

  if (!color) {
    color = "#000000";
  }

  if (png) {
    colorCircle = <img src={png} className={styles.png} alt="color" />;
  } else {
    colorCircle = (
      <div
        style={{
          backgroundColor: color,
          border:
            !isSelected && color === "#FFFFFF" ? "1px solid #ccc" : undefined,
        }}
        className={styles.color}
      />
    );
  }

  return (
    <React.Fragment>
      <DarkTooltip title={tooltip ? t(`colors.${tooltip}`) : ""} followCursor>
        {isSelected ? (
          <div className={styles.selected} style={{ padding: png ? 0 : 1 }}>
            {colorCircle}
          </div>
        ) : (
          <div className={styles.notSelected}>{colorCircle}</div>
        )}
      </DarkTooltip>
    </React.Fragment>
  );
};
