import { SxProps, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Box, FlexColBox, Icon } from "..";
import { palette, Styles } from "../../../styles/theme";
import { getConditionnalStyle, mergeSx } from "../../../utils/themeUtils";
import { MaterialSymbolType } from "../Icon/IconConstant";

const styles: Styles = {
  container: {
    cursor: "pointer",
    display: "flex",
    maxWidth: "fit-content",
    padding: "6px 12px 6px 10px",
    alignItems: "center",
    borderRadius: "12px",
    border: `1px solid ${palette.grey.darkGrey}`,
    gap: "8px",
    userSelect: "none",
    margin: "1px",
    ":hover": {
      margin: "1px",
      background: `linear-gradient(90deg, rgba(84, 172, 222, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%), ${palette.lightBorderGradient}`,
      border: `1px solid transparent`,
    },
  },
  active: {
    background: `linear-gradient(white, white) padding-box, ${palette.borderGradient}`,
    margin: "0",
    border: `2px solid transparent`,
    ":hover": {
      margin: "0",
      background: `linear-gradient(white, white) padding-box, ${palette.borderGradient}`,
      border: `2px solid transparent`,
    },
  },
  disabled: {
    background: palette.grey.lightGrey,
    margin: "1px",
    border: `1px solid ${palette.grey.darkGrey}`,
    cursor: "default",
    ":hover": {
      background: palette.grey.lightGrey,
      margin: "1px",
      border: `1px solid ${palette.grey.darkGrey}`,
    },
  },
};

export interface ChipProps<T> {
  id: T;
  color: string;
  labelKey: string;
  icon: MaterialSymbolType;
  onClick: (id: T | any) => void;
  active?: boolean;
  description?: string;
  disabled?: boolean;
  containerSx?: SxProps;
  iconSize?: number;
}

const Chip = <T,>({
  id,
  color,
  labelKey,
  icon,
  active,
  onClick,
  description,
  disabled,
  containerSx,
  iconSize,
}: ChipProps<T>) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={mergeSx(
        styles.container,
        getConditionnalStyle(active, styles.active),
        getConditionnalStyle(disabled, styles.disabled),
        getConditionnalStyle(!!containerSx, containerSx)
      )}
      onClick={!disabled ? () => onClick(id) : undefined}
    >
      {icon && <Icon icon={icon} size={iconSize ?? 12} color={color} />}
      <FlexColBox gap={"2px"}>
        <Typography variant="textSm" color={color}>
          {t(labelKey)}
        </Typography>
        <Typography variant="textXs" color={palette.grey.primary}>
          {description}
        </Typography>
      </FlexColBox>
    </Box>
  );
};

export default memo(Chip);
