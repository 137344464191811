import { createContext, Dispatch, SetStateAction } from "react";

type AppLoadingContextType = {
  isAppLoading?: boolean;
  setIsAppLoading: Dispatch<SetStateAction<boolean>>;
};

export const AppLoadingContext = createContext<AppLoadingContextType>({
  isAppLoading: false,
  setIsAppLoading: () => undefined,
});
