import { Theme, Typography, useTheme } from "@mui/material";
import { createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box, Label } from "../../../common/components";
import { palette, spacing } from "../../../styles/theme";
import { DesignFamily, DesignProduct, Product } from "../../../types";

const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      pt: { xs: spacing.smallMobile, lg: spacing.smallDesktop },
      pb: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "165px",
      maxWidth: "214px",
      flex: "1 0 0",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        px: 2,
      },
      borderRadius: "12px",

      border: `2px solid transparent`,
      "&:hover": {
        border: `2px solid ${palette.grey.lightGrey}`,
      },
      ":active": {
        border: `2px solid ${palette.blue.secondary}`,
      },
    },
    itemContainer: {
      "&>img": {
        minWidth: "150px",
        maxWidth: "150px",
        maxHeight: "150px",
        verticalAlign: "middle",
      },
    },
    itemDescriptionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  });

interface Props {
  product: Product;
  onCreateDesign: (
    family: DesignFamily,
    product: DesignProduct,
    labelTag: string
  ) => void;
  dataTest: string;
  isFiltering: boolean;
}

export const ProductModalItem = (props: Props) => {
  const {
    product: { imgName, comingSoon, labelTag, family, product, minQty, line },
    onCreateDesign,
    dataTest,
    isFiltering,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box
      data-test={dataTest}
      sx={[
        styles.container,
        //  comingSoon ? null : styles.containerSelectable
      ]}
      onClick={
        !comingSoon
          ? () => onCreateDesign(family, product, t(labelTag))
          : undefined
      }
    >
      <Box sx={styles.itemContainer}>
        <img src={imgName} alt={t(labelTag)} />
      </Box>
      <Box
        sx={styles.itemDescriptionContainer}
        gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
      >
        <Typography textAlign="center" variant="h5">
          {t(labelTag)}
        </Typography>
        {!comingSoon && (
          <Label sx={{ textAlign: "center", fontSize: 12 }}>
            {t("products.modal.minimum_qty", { minQty: minQty })}
          </Label>
        )}
        {comingSoon && (
          <Label sx={{ textAlign: "center", fontSize: 12 }}>
            {t("products.modal.coming")}
          </Label>
        )}
        {/* <ProductLine line={line} showDescription={!isFiltering} /> */}
      </Box>
    </Box>
  );
};
