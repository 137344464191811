import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum: AwsRum | undefined;

function init() {
    try {
        // Singleton
        if (!awsRum) {
            const config: AwsRumConfig = {
                sessionSampleRate: 1,
                identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL,
                endpoint: process.env.REACT_APP_RUM_ENDPOINT,
                telemetries: ["performance", "errors", "http"],
                allowCookies: true,
                enableXRay: true,
                disableAutoPageView: true,  // Required to merge paths regardless of uuid
            };

            const APPLICATION_ID: string = process.env.REACT_APP_RUM_APPLICATION_ID || "";
            const APPLICATION_VERSION: string = process.env.REACT_APP_RUM_APPLICATION_VERSION || '1.0.0';
            const APPLICATION_REGION: string = process.env.REACT_APP_RUM_APPLICATION_REGION || 'ca-central-1';

            awsRum = new AwsRum(
                APPLICATION_ID,
                APPLICATION_VERSION,
                APPLICATION_REGION,
                config
            );
        }
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}

init();

export default awsRum;
