import { memo, useState } from "react";
import { palette, spacing, Styles } from "../../../styles/theme";
import type { Image } from "../../../types";
import {
  getConditionnalStyle,
  useThemeBreakpoints,
} from "../../../utils/themeUtils";
import { Box } from "../Box";
import { Icon } from "../Icon";

const styles: Styles = {
  container: {
    display: "flex",
    alignItems: "center",
  },
};

interface Props {
  images: (string | Image)[];
  containerHeight?: string;
  containerWidth?: string;
  onClick?: () => void;
}

const ImageSlider = ({
  images,
  containerHeight,
  containerWidth,
  onClick,
}: Props) => {
  const [currentImage, setCurrentImage] = useState(0);

  const { isMobile } = useThemeBreakpoints();

  const onNavButtonClick = (action: "previous" | "next") => () => {
    setCurrentImage((prev) => {
      if (action === "next") {
        if (prev + 1 > images.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      } else {
        if (prev - 1 < 0) {
          return images.length - 1;
        } else {
          return prev - 1;
        }
      }
    });
  };
  const isImageType = typeof images[0] === "object";
  const src = isImageType
    ? (images[currentImage] as Image).url
    : (images[currentImage] as string);
  const alt = isImageType
    ? (images[currentImage] as Image).side
    : (images[currentImage] as string);

  return (
    <Box
      sx={{
        ...styles.container,
      }}
    >
      <Icon
        onClick={onNavButtonClick("previous")}
        icon="chevron_left"
        size={isMobile ? 18 : 24}
        color={palette.grey.nearBlack}
      />
      <Box
        sx={{
          minWidth: containerWidth,
          minHeight: containerHeight,
          maxWidth: containerWidth,
          maxHeight: containerHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "6px", lg: spacing.mediumDesktop },
          ...getConditionnalStyle(!!onClick, { cursor: "pointer" }),
        }}
        onClick={onClick}
      >
        <img
          src={src}
          alt={alt}
          style={{ maxHeight: isMobile ? "84px" : "152px", maxWidth: "100%" }}
        />
      </Box>
      <Icon
        icon="chevron_right"
        size={24}
        color={palette.grey.nearBlack}
        onClick={onNavButtonClick("previous")}
      />
    </Box>
  );
};

export default memo(ImageSlider);
