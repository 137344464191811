import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { palette } from "../../../styles/theme";
import { useCurrency } from "../../../utils/Currencies";

const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const { isUSD, setUSD, setCAD } = useCurrency();
  return (
    <Typography
      variant="textSm"
      onClick={(e) => {
        e.preventDefault();
        (isUSD() ? setCAD : setUSD)();
      }}
      sx={{
        color: palette.blue.secondary,
        cursor: "pointer",
        ":hover": {
          textDecoration: "underline",
        },
      }}
    >
      {isUSD()
        ? t("general.currency.change_to_cad")
        : t("general.currency.change_to_usd")}
    </Typography>
  );
};

export default LanguageSwitcher;
